import { GetterTree } from 'vuex';
import { RootState } from '@/store/types'
import { AdminState } from './types'

export const getters: GetterTree<AdminState, RootState> = {




};
