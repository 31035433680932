import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import LoginPage from '@/views/login-page/login-page.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {path:'/',name:'login', redirect:'login'},
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
