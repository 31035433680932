import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '@/store/types'
import { AuthDto } from '@/models/dto/auth-dto';
import { AuthService } from '@/services/auth.service';
import { i18n } from '@/plugins/i18n';
import Vue from 'vue';


export const actions: ActionTree<AuthState, RootState> = {

   logIn(context, payload: AuthDto){
       return AuthService.getInstance('').auth(payload)
           .then(value => {
              console.log(value)
              if (value.status == 200 ) {
                  window.location = value.request.responseURL
              }
              /*if (value.data)
                console.log(value.data)
                console.log(value.apiError)
              if (value.apiError) {
                console.log(value.apiError)
                const code = 'errors.' + value.apiError.error_code + '.message'
                const error = i18n.t(code)

                Vue.$toast.error(error)
              }*/
           })
           .catch(error => {
              console.log(error)
           })
   }

}
