<template>
  <b-modal
    content-class="mdp-modal-class"
    id="modal-infos-mdp"
    size="lg"
    hide-footer
    header-class="no-margin-no-padding"
    header-close-variant="light"
  >
    <template #modal-title>
      <b-container fluid>
        <b-row class="align-items-center">
          <b-col class=" p-0 mt-2">
            <span class="material-icons ">privacy_tip</span>
          </b-col>
          <b-col class="p-0"><span>Infos</span></b-col>
        </b-row>
      </b-container>
    </template>
    <div class="text-center">
      <p class="m-0"><span class="modal-class-font ">Pour réinitialiser ou obtenir un nouveau mot de passe </span></p>
      <p class="m-0"><span class="modal-class-font ">merci de contacter le service informatique</span></p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'EventModal'
};
</script>

<style src="./event-modal.scss" lang="scss" scoped/>
