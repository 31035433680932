
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { auth } from '@/mixins/auth.mixin';
import LoginModal from '@/components/login-modal/login-modal.vue';
import EventModal from '@/components/event-modal/event-modal.vue';

export default Vue.extend({
  name: 'LoginPage',
  components: { EventModal, LoginModal },
  mixins: [auth],

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters('redirection', ['getUrlFrom'])
  },

  methods: {
    ...mapActions('admin', ['search']),

    show() {
      this.$modal.show('login-form');
    },

    goToOrchestrator(){
      window.location.replace(process.env.VUE_APP_ORCHESTRATOR)
    },

    hide() {
      this.$modal.hide('login-form');
    },

  },

});
