

import Vue from 'vue';
import { auth } from '@/mixins/auth.mixin';
import { mapActions, mapGetters } from 'vuex';
import { AuthDto } from '@/models/dto/auth-dto';
import { params } from '@/mixins/params.mixin';

export default Vue.extend({
  name: 'LoginModal',
  mixins: [auth, params],
  data() {
    return {
      form: {
        password: '',
        username: ''
      },
    };
  },

  computed: {
    //...mapGetters('redirection', ['getUrlFrom'])
  },

  methods: {
    ...mapActions('auth', ['logIn']),

    opened() {
      this.$refs.email;
    },

    showPasswordReset() {
      this.$modal.show('password-reset');
      this.$modal.hide('login-form');
    },

    onSubmit(event: Event) {
      event.preventDefault();

      const authPayload: AuthDto = this.buildAuthPayload(this.form.username,
        this.form.password, this.clientId, this.scope, this.redirectUri,
        this.codeChallenge)

      this.logIn(authPayload)
    }

  }

});
