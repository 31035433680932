import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VModal from 'vue-js-modal';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { i18n } from '@/plugins/i18n'

import Toast from 'vue-toastification';
import { PluginOptions } from 'vue-toastification/dist/types/src/types';
import 'vue-toastification/dist/index.css'

const options: PluginOptions = {
}

Vue.use(Toast, options);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VModal)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
