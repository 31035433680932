import {Module} from 'vuex';
import {RootState} from '@/store/types';
import {getters} from '@/store/modules/auth/getters';
import {actions} from '@/store/modules/auth/actions';
import {mutations} from '@/store/modules/auth/mutations';
import { AuthState } from './types';

export const state: AuthState = {

};

export const auth: Module<AuthState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};
