
//http://localhost:8080/login?client_id=orchestrator-client&scope=sm-scopeorchestrator-scopeopenid&redirect_uri=https://mairie.golfe3/apps/orchestrator&code_challenge=dfr_RAp5R23HJCR8gD_f3C7ojJQHp-uGHWYLxJ1oOLU=

export const params = {

  data() {
    return {
      clientId: null,
      scope: null,
      redirectUri: null,
      codeChallenge: null,
    }
  },


  mounted() {

    if ('client_id' in this.$route.query)
      this.clientId = this.$route.query.client_id

    if ('scope' in this.$route.query)
      this.scope = this.$route.query.scope

    if ('redirect_uri' in this.$route.query)
      this.redirectUri = this.$route.query.redirect_uri

    if ('code_challenge' in this.$route.query)
      this.codeChallenge = this.$route.query.code_challenge

  }
}
