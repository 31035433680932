import { ApiResponse } from '@/models/http/ApiResponse'
import { HttpBaseService } from './httpBase.service'
import { AuthDto } from '@/models/dto/auth-dto';

export class AuthService extends HttpBaseService {

    private static classInstance?: AuthService
    static apiBaseUrl = 'https://darkshield.nathos.dev/api/v1/darkshield-server/'

    constructor(token:string) {
        super(token, AuthService.apiBaseUrl)
    }

    public static getInstance(token:string): AuthService {

        if(!this.classInstance) {
            this.classInstance = new AuthService(token)
        }
        return this.classInstance
    }

    public auth(payload: AuthDto) {
        console.log('request')
        console.log(payload)
        return this.instance.post('auth/realms/mairie-golfe3/protocol/openid-connect/auth', payload)


    }



}
