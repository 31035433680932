import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import { RootState } from './types'
import { admin } from './modules/admin/index';
import { auth } from './modules/auth/index';


Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0'
    },
    modules: {
        admin: admin,
        auth: auth
    },
    devtools: false
}

export default new Vuex.Store<RootState>(store)
