import { AuthDto } from '@/models/dto/auth-dto';


export const auth = {

  methods: {
    getHashValue(value: string): string {
      return value.slice(2);
    },

    getUrlParamValue(value: string, sliceNumber: number): string {
      return value.slice(sliceNumber);
    },

    buildAuthPayload(username: string, password: string, clientId: string, scope: string,
                     redirectUri: string, codeChallenge: string): AuthDto{

      const auth_dto: AuthDto = {
        client_id: clientId,
        scope: scope,
        redirect_uri: 'http://localhost:5000',
        response_type: 'code',
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
        username: username,
        password: password,
      }

      return auth_dto

    }

  }

}


